<template>
    <DrugbookEntries status="all" />
</template>
<script>
import DrugbookEntries from '../../components/drugbook/drugbookEntries.vue';

export default {
    components: {
        DrugbookEntries,
    },
    data() {
        return {};
    },
}
</script>